<template>
  <component :is="tag" v-if="toPath.length === 0" @click="clicked"
    v-bind:class="{
      'clickable': !disabled,
      'disabled': disabled,
      'extra_classes': extra_classes,
    }">
    <slot></slot>
  </component>
  <router-link v-else-if="isVueRoute && isVuePage && !disabled && !preventDefault"
               @click="clicked($event)"
               class="clickable"
               :tag="tag"
               :to="params.length ? { path: toPath, params: params} : toPath"
               :target="target"
  >
    <slot></slot>
  </router-link>
  <component v-else-if="tag === 'a' && preventDefault && toPath !== ''" :href="toPath" :target="target" is="a"
   @click.prevent="clicked($event)"
   v-bind:class="{
      'clickable': !disabled,
      'disabled': disabled,
      'extra_classes': extra_classes,
    }">
    <slot></slot>
  </component>
  <component v-else-if="tag === 'a' && toPath !== ''" :href="toPath" :target="target" is="a" v-bind:class="{
      'clickable': !disabled,
      'disabled': disabled,
      'extra_classes': extra_classes,
    }">
    <slot></slot>
  </component>
  <component v-else :is="tag" @click.left.exact.prevent.stop="navigateProgrammatically" v-bind:class="{
      'clickable': !disabled,
      'disabled': disabled,
      'extra_classes': extra_classes,
    }">
    <slot></slot>
  </component>
</template>

<script>

import Utility from '@/vue/utility/utility';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';

/**
 * The <medimo-link> will use a Vue router when the route exists, and a "normal" <a> link when it does not.
 */
export default {
  components: {},

  emits: ['click'],

  props: {
    // Sets the outer HTML element of the link
    'tag': {default: 'a'},
    // The URL to navigate to
    'to': {
      type: String,
      required: true
    },
    'target': {default: '_self'},
    'history': {default: 0}, // The amount of steps to go back with a javascript navigation
    'params': {
      default: () => {
        return {};
      }
    }, // Additional URL parameters
    'trigger': {default: false}, // When switched will trigger a programmatic navigation to the URL
    'disabled': {default: false},
    'extra_classes': {default: ''},
    'preventDefault': {default: false}, // Set to true to only emit @click and handle navigation elsewhere
  },

  mixins: [
    NavigatesProgrammatically
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    clicked(event) {
      this.$emit('click', event);
    }
  },

  watch: {
    trigger(triggered) {
      this.navigateProgrammatically();
    }
  }
};
</script>
