<template>
  <div style="position:relative;"
       :class="'medimo-input ' + (validation_class.length ? 'form-control ' + validation_class : '') + ' ' + (sub_text.length ? 'with-subtext' : '')">
    <input :class="'form-control ' + input_class"
           ref="input"
           :maxlength="maxlength"
           :style="extra_styles"
           :type="type"
           v-bind:value="modelValue"
           @blur="onBlur($event)"
           @focus="onFocus($event)"
           @keyup="onKeyEvent('keyup', $event)"
           @keydown="onKeyEvent('keydown', $event)"
           :name="name"
           :placeholder="placeholder"
           :disabled="disabled"
           autocomplete="off"
           @input="onInput"
           :min="min"
           :max="max"
           :step="step"
           :title="placeHolderAsTitle ? placeholder : ''"
           @click="$emit('click')"
    >
    <medimo-tooltip v-if="help_text.length" :content="help_text">
      <fa-icon icon="fa-solid fa-circle-question" class="text-primary" size="lg" />
    </medimo-tooltip>
    <span class="text-small text-muted" v-if="hasSubText">{{ sub_text }}</span>
  </div>
</template>

<script>

import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import {nextTick} from 'vue';

export default {

  emits: ['keyup', 'focus', 'blur', 'update:modelValue', 'keydown_backspace', 'keydown_tab', 'keydown_enter', 'keydown_left', 'keydown_up', 'keydown_right', 'keydown_down', 'keydown_period', 'keyup_backspace', 'keyup_tab', 'keyup_enter', 'keyup_left', 'keyup_up', 'keyup_right', 'keyup_down', 'keyup_period', 'click'],

  components: {
    MedimoTooltip,
  },
  props: {
    'modelValue': {default: '', type: [String, Number, null]}, // Dit is in Vue3 de nieuwe value
    'name': {default: ''},
    'label': {default: ''},
    'disabled': {default: false},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'type': {default: 'text'},
    'extra_styles': {default: ''},
    'help_text': {default: ''},
    'sub_text': {default: ''},
    // This automatically hides the zero when clicking on the input, and it has a 0
    'hide_zero': {default: false},
    // Clears the input when a users clicks on it
    'clear_on_click': {default: false},
    // Use this to give the input a special class or ID, handy for testing
    'input_class': {default: ''},
    'input_id': {default: ''},

    // Attach to a boolean value to have the input be focused when switched to true
    'focus': {default: false},
    'maxlength': {default: ''},

    // Zet deze op FALSE om de force update te disablen. In het geval van een ranged number input
    // of andere waarden waar die waarde met :value wordt ingevoerd kan dat wenselijk zijn
    'forceUpdate': {default: true},

    'placeHolderAsTitle': {default: false},

    'min': {},
    'max': {},
    'step': {},
  },

  mixins: [],

  data: function () {
    return {
      temporaryValue: '',
      // Needed to have a custom event translator instead of defining both the keyCode and human type
      // Backspace and Period arent natively supported by Vue, so this covers all bases
      keys: {
        8: 'backspace',
        9: 'tab',
        13: 'enter',
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        190: 'period',
      }
    };
  },

  computed: {
    hasHelpText() {
      return this.help_text.length;
    },
    hasSubText() {
      return this.sub_text.length;
    }
  },

  created() {
    //
  },

  mounted() {
    if (this.focus) {
      this.setFocus();
    }
  },

  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onBlur(event) {
      // When hide_zero or clear_on_click is enabled we want to set the value again when the user input was empty
      if (this.hide_zero || this.clear_on_click) {
        const vm = this;
        const oldValue = this.hide_zero ? '0' : this.temporaryValue;

        setTimeout(function () {
          if (vm.modelValue?.length <= 0) {
            //Do not reset if the input is still active:
            const activeElement = document.activeElement;
            if (vm.$refs.input && activeElement !== vm.$refs.input) {
              vm.$emit('update:modelValue', oldValue);
            }
          }
        }, 1);
      }
      // Emit @blur regardless
      this.$emit('blur', event.target.value);

      // Onderstaand is nodig om eventuele upstream updates van de :value goed te tonen.
      // Dit _zou_ in theorie niet nodig moeten zijn als die chain goed in elkaar zit, maar het is net zo praktisch dat hier
      // af te vangen. Aangezien het een @blur is is het een veilige aanname dat de gebruiker "klaar" is met dit element, en we
      // dus een update kunnen forcen, en daarmee de input value rerenderen.
      //
      // Een situatie waarbij dit mis kan gaan is als een waarde upstream ge-transformed wordt qua input.
      // Dan wordt deze upstream hetzelfde geacht waardoor de reactive update downstream uit blijft, en de input niet geupdate wordt.
      // Dit is bijv. het geval van tijd, dat 22 upstream identiek is aan 22:00, dus niet veranderd, en dus "hier" de 22 blijft staan
      if (this.forceUpdate) {
        setTimeout(() => {
          this.$forceUpdate();
        }, 1);
      }
    },
    onFocus(event) {
      this.temporaryValue = this.modelValue;

      if (this.hide_zero) {
        if (this.modelValue !== '0.' && parseFloat(String(this.modelValue).replace(",", ".")) === 0) {
          this.$emit('update:modelValue', '');
        }
      } else if (this.clear_on_click) {
        this.$emit('update:modelValue', '');
      }

      // Always emit the focus
      this.$emit('focus');
    },
    onKeyEvent(type, event) {
      if (this.keys[event.keyCode] !== undefined) {
        const eventName = type + '_' + this.keys[event.keyCode];
        this.$emit(eventName, event);
      }
      else {
        // Any keyup
        this.$emit(type, event); // keyup of keydown
      }
    },
    setFocus() {
      nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },

  watch: {
    focus(value) {
      if (value) {
        this.setFocus();
      }
    },
  }
};
</script>
